<template>
  <footer id="footer">
    <back-to-top class="back-to-top"></back-to-top>
    <FooterDeliverySeparator />
    <div class="container">
      <div
        v-if="accdata.length > 0"
        class="
          d-flex
          flex-column flex-md-row flex-wrap
          justify-content-start
          flex-container flex-column flex-md-row
        "
      >
        <div
          class="accordion-block"
          v-for="(accdatum, index) in accdata"
          :key="index"
        >
          <b-link
            href="#"
            class="text-uppercase acc-opener block-heading"
            v-b-toggle="index.toString()"
            v-if="typeof accdatum != 'undefined'"
          >
            {{ accdatum.title }}
          </b-link>
          <FooterAccordion :accordionData="accdatum" :index="index" />
        </div>

        <div class="contacts-block" v-if="typeof contactData == 'object'">
          <span class="text-uppercase block-heading">
            {{ contactData.title }}
          </span>
          <cmsBlockSimple
            v-if="typeof contactData == 'object'"
            :identifier="contactData.identifier"
          />
        </div>
        <div class="social-block">
          <div class="social-rating-img d-block">
            <client-only>
              <div v-if="allCookies == true">
                <iframe
                  frameborder="0"
                  allowtransparency="true"
                  src="https://www.kiyoh.com/retrieve-widget.html?color=white&allowTransparency=false&button=true&lang=nl&tenantId=98&locationId=1065068"
                  width="120"
                  height="160"
                ></iframe>
              </div>
            </client-only>
          </div>
        </div>
      </div>
      <div
        class="
          d-flex
          flex-column flex-lg-row flex-container
          delivery-and-payment
        "
      >
        <div class="payment">
          <span class="text-uppercase block-heading d-block">{{
            $t("secure_payments")
          }}</span>
          <div v-if="typeof paymentPartners == 'object'">
            <ul class="payment-partners">
              <li v-for="(image, index) in paymentPartners.slides" :key="index">
                <b-link class="img" :href="image.link" target="_blank">
                  <img
                    :src="image.media.url"
                    :alt="image.title"
                    class="delivery-icons"
                  />
                </b-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="delivery">
          <span class="text-uppercase block-heading d-block">
            {{ $t("delivered_by") }}
          </span>
          <div v-if="typeof deliveryPartners == 'object'">
            <ul class="delivery-partners">
              <li
                v-for="(image, index) in deliveryPartners.slides"
                :key="index"
              >
                <b-link class="img" :href="image.link" target="_blank">
                  <img
                    class="delivery-icons"
                    :src="image.media.url"
                    :alt="image.title"
                  />
                </b-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <FooterCopyRight />
  </footer>
</template>

<script>
import FooterDeliverySeparator from "@/base/core/components/FooterDeliverySeparator";
import FooterAccordion from "@/base/core/components/FooterAccordion";
import cmsBlockSimple from "@/base/core/components/core/BlockSimple";
import BackToTop from "@/base/core/components/core/BackToTop";
import FooterCopyRight from "@/base/core/components/FooterCopyRight";
import ClientOnly from "vue-client-only";

export default {
  name: "Footer",
  components: {
    FooterDeliverySeparator,
    FooterAccordion,
    cmsBlockSimple,
    BackToTop,
    ClientOnly,
    FooterCopyRight,
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
  computed: {
    accdata() {
      return [
        this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`](
          "footer_menu_information"
        ),
        this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`](
          "footer_menu_service"
        ),
        this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`](
          "footer_menu_quick_links"
        ),
      ];
    },
    allCookies() {
      return this.$gtm.enabled();
    },
    deliveryPartners() {
      return this.$store.getters["sliders/getSliderByIdentifier"](
        "delivery_partners"
      );
    },
    paymentPartners() {
      return this.$store.getters["sliders/getSliderByIdentifier"](
        "payment_providers"
      );
    },
    contactData() {
      return this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`](
        "footer_contact"
      );
    },
  },
  watch: {},
  data: () => ({}),
};
</script>
